import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { withPrefix, Link } from "gatsby";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import "./pp.css"; // Import CSS file here

export default function Privacy() {

    return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Privacy Policy</title>
                <script src={withPrefix('js/privacy.js')} type="text/javascript" />
            </Helmet>
            <header style={{ background: "#222", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/logo.png" alt="frompdftodoc logo" />
            </header>
            <div name="termly-embed" data-id="7e71c5c7-5e8f-4929-846b-a8b949b64610"></div>
            <RawFooter />
        </HomepageLayout>
    );
}

